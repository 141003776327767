// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newtab-js": () => import("../src/pages/newtab.js" /* webpackChunkName: "component---src-pages-newtab-js" */),
  "component---src-pages-pay-js": () => import("../src/pages/pay.js" /* webpackChunkName: "component---src-pages-pay-js" */),
  "component---src-pages-pending-js": () => import("../src/pages/pending.js" /* webpackChunkName: "component---src-pages-pending-js" */),
  "component---src-pages-tab-js": () => import("../src/pages/tab.js" /* webpackChunkName: "component---src-pages-tab-js" */)
}

